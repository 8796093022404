<template>
    <h1>{{ Field.Group }} / {{ Field.Name }}</h1>
        <Chart type="bar" :options="ChartOptions" :data="ChartData" v-if="Field.Type == 'int' || Field.Type == 'filesize'"/>
        <DataTable
            :rowHover="true"
            :paginator="true"
            :rows="15"
            :scrollable="true" scrollHeight="400px"
            :loading="loading"
            :value="data"
        >
            <Column field="TimeStamp" header="Date" :sortable="true"></Column>
            <Column field="Value" header="Value" :sortable="true">
                <template #body="v">
                    {{ printFormat(Field.Type, v.data.Value) }}
                </template>
            </Column>

        </DataTable>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MetricService from "@/services/metrics";
import Chart from 'primevue/chart';

export default {
    name: "EmbeddedMetrics",
    components: {DataTable, Column, Chart},
    props: ['account', 'field'],
    data() {
        return {
            ChartOptions: {
                responsive: true,
                hoverMode: 'index',
                stacked: false,
                animation:{duration:0},
                legend:{display:false},

                scales: {
                    xAxes: [{
                        ticks: {display: false},
                        // gridLines:{display:false}
                    }],
                    yAxes: [{

                        // gridLines:{
                            // display: false,
                            // drawTicks:false
                        // },
                        ticks: {
                            display: false,
                            gridLines:{display:false},
                            suggestedMin: undefined,
                            suggestedMax: undefined
                        },
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                    },
                 ]
                }
            },
            ChartData: null,
            Account: this.account,
            Field: this.field,
            data: [],
            loading: false,
        }
    },
    service: null,
    watch: {
        account: function (v) {
            this.Account = v
            this.loadMetrics()
        },
        field: function (v) {
            this.Field = v
            this.loadMetrics()
        }
    },
    created() {
        this.service = new MetricService()
    },
    mounted() {
        this.loadMetrics()
    },
    methods: {
        loadMetrics() {
            this.loading = true
            this.service.getMetricDetail(this.account, this.field.Name).then(data => {
                this.data = data

                let labels = []
                let chartdata = []

                this.ChartData = {labels:labels, datasets:[{backgroundColor: '#42A5F5', data:chartdata, fill:true, steppedLine:true}]}

                data.forEach(v => {
                    chartdata.push(v.Value)
                    labels.push(v.TimeStamp)
                })
                labels = labels.reverse()
                chartdata = chartdata.reverse()
                let max = Math.max.apply(null, chartdata)
                let min = Math.min.apply(null, chartdata)

                let threshold = max/min * 5
                this.ChartOptions.scales.yAxes[0].ticks.suggestedMax = max + threshold
                this.ChartOptions.scales.yAxes[0].ticks.suggestedMin = min - threshold
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>
h1 {
    font-size:11pt;
    padding:10px;
    margin: 0;

}
</style>