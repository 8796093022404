<template>

    <div class="p-fluid p-formgrid p-grid filters">
        <div class="p-field p-col-3">
            <label for="delta">Select delta</label>
            <Dropdown id="delta" placeholder="Delta" :options="deltaOptions" optionLabel="label" optionValue="value" v-model="InternalDelta"/>
        </div>
        <div class="p-field p-col-3">
            <label for="groups">Select Group</label>
            <MultiSelect id="groups" placeholder="Groups" :options="Groups" :showClear="true"  v-model="groupsFilter"/>
        </div>
        <div class="p-field p-col-2">
            <label for="switcher">Show only changes</label><br/>
            <InputSwitch id="switcher" v-model="onlyChanged" />
        </div>

        <div class="p-field p-col-2">
            <label for="switcherBelowZero">Show below zero</label><br/>
            <InputSwitch id="switcherBelowZero" v-model="switcherBelowZero" />
        </div>

    </div>

    <div class="p-p-3">
        <Sidebar v-model:visible="ShowSidebar" position="right" class="p-sidebar-md">
            <EmbeddedMetrics :account="Account" :field="ActiveField"  />
        </Sidebar>

        <template v-for="(v,k) of Data" v-bind:key="k">
            <template v-for="o of v" v-bind:key="o">

                <template v-if="groupsFilter.length === 0 ||groupsFilter.includes(k)">

                    <div class="box"
                         :class="o.Metric.Delta > 0 ? 'pos' : o.Metric.Delta < 0 ? 'neg' : '' "
                         v-if="(onlyChanged === false || o.Metric.Delta !== 0) && (switcherBelowZero === false || o.Metric.Value > 0)"
                         @click="toggleOverlay(o.Field)"
                    >
                        <h2>{{ o.Field.Label }}</h2>
                        <b>{{ printFormat(o.Field.Type, o.Metric.Value, false) }}
                            <i class="delta" v-if="o.Metric.Delta !== 0">{{ printFormat(o.Field.Type, o.Metric.Delta, true) }}</i>
                        </b>
                    </div>

                </template>
            </template>

            <div style="clear:both"></div>

        </template>
    </div>

</template>

<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect"
import InputSwitch from 'primevue/inputswitch';
import EmbeddedMetrics from "@/components/details/EmbeddedMetrics";
import Sidebar from 'primevue/sidebar';

export default {
    name: "DataDetails",
    props: ["Data", "Groups", "Account", "Delta"],
    components: {
        Dropdown,
        MultiSelect,
        InputSwitch,
        EmbeddedMetrics,
        Sidebar,
    },
    emits:['deltachange'],
    data() {
        return {
            InternalDelta:null,
            ShowSidebar: false,
            ActiveField: null,
            onlyChanged: false,
            switcherBelowZero: false,
            groupsFilter:[],
            deltaOptions: [
                {value:0,  label:"No delta"},
                {value:1,  label:"1 Day delta"},
                {value:2,  label:"2 Day delta"},
                {value:3,  label:"3 Day delta"},
                {value:4,  label:"4 Day delta"},
                {value:7,  label:"1 Week delta"},
                {value:14, label:"2 Week delta"},
                {value:30,  label:"30 Days delta"},
                {value:60,  label:"60 Days delta"},
            ],
        }
    },
    watch: {
      InternalDelta: function (v) {
          this.$emit('deltachange', v)
      }
    },
    created() {
        this._Delta = this.Delta
    },
    methods: {
        toggleOverlay(key) {
            this.ActiveField = key
            this.ShowSidebar = true
        }
    }
}
</script>

<style scoped>
.box {
    display:inline-block;
    background-color: #f1f1f1;
    cursor: pointer;
    width: 270px;
    height:70px;
    font-weight: 600;
    margin: 0 7px 7px 0;
    padding: 10px
}

h1 {
    font-size: 14pt;
}
.box h2 {
    margin:0;
    padding:0;
    font-size:11pt;
    opacity:0.5;
}

.box b {
    display: block;
    font-size: 14pt;
}

.box b i.delta {
    font-weight: normal;
    font-style: normal;
    font-size: 12pt;
}

.neg {
    background-color: red;
    color: white;
}

.pos {
    background-color: green;
    color: white;
}

.filters {
    background-color: #607d8b;
    padding: 12px;
}
.filters label {
    color: white;
}

</style>